/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Tooltip,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { auth, db } from "../../firebase/firebasev2";
import { doc, getDoc } from "firebase/firestore";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import StripedGrid from "../../Components/StripeDataGrid";
import CustomTextField from "../../Components/CustomTextField";
import { useSnackbar } from "notistack";
import { servidor } from "../../servidor";
import { validateToken } from "../../Apis/validateToken";
import timeStamp_toDateComplete from "../../Utils/timeStamp_toDateComplete";
import { castBranche } from "../../Utils/castBranche";
import timeStamp_newDate_toStringComplete from "../../Utils/timeStamp_newDate_toStringComplete";

const getUsers = async (uid) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await servidor.get("movilabs/usuarios/", {
      headers: {
        token,
        uid,
      },
    });
    if (response?.status !== 200) {
      throw new Error("Error en la respuesta del servidor");
    }
    return response.data;
  } catch (error) {
    console.log("e.users", error);
  }
};

const ListUsers = () => {
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const user = auth.currentUser;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [store, setStore] = useState();
  const getData = async () => {
    const response = await validateToken("", user.uid);
    setStore(response.store);
  };

  useEffect(() => {
    if (user.uid) {
      setLoading(true);
      getUsers(user.uid)
        .then(({ users = [] }) => setUsers(users))
        .then(() => setLoading(false))
        .catch(console.log);
    }
    getData();
  }, [user]);

  const groupedData = {}; //Agrupar por sucursal
  users.forEach((item) => {
    const branch = item.branch || "Default"; // Asegúrate de tener una propiedad branch en tus datos
    if (!groupedData[branch]) {
      groupedData[branch] = [];
    }
    groupedData[branch].push(item);
  });

  // Modal details users
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [historialMov, setHistorialMov] = useState([]);
  console.log("historialMov", historialMov);
  const handleClickOpen = async (item) => {
    console.log(item);
    setSelectedItem(item);
    setOpen(true);
    await getHistorial(item).then((response) => {
      let folio = 0;
      if (response?.Movimientos.length > 0) {
        const historial = response?.Movimientos.map((doc) => {
          doc.np = folio + 1;
          folio++;
          return doc;
        });
        setHistorialMov(historial);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setBlock(false);
  };

  const [block, setBlock] = useState(false);
  const [form, setForm] = useState({
    motivo: "",
  });

  const unsubscribeUser = async () => {
    setSpinner(true);
    if (!form?.motivo) {
      enqueueSnackbar("Es necesario especificar un motivo", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      setSpinner(false);
    } else {
      const pathParts = selectedItem.path.split("/");
      const uidUnsubscribe = pathParts[pathParts.length - 1];

      try {
        // Obtener la ruta del usuario actual
        const pathResponsable = `Franquicias/${store}/Personal/${user.uid}`;

        const response = await servidor.post(
          "/movilabs/unsubscribeUser",
          {
            store_id: store,
            userId: uidUnsubscribe,
            motivo: form.motivo,
            responsable: pathResponsable,
          },
          {
            headers: {
              uid: user.uid,
            },
          }
        );
        console.log(response.data.reference);
        setSpinner(false);

        enqueueSnackbar(
          `${selectedItem.name} ha sido inhabilitado exitosamente`,
          {
            variant: "success",
            preventDuplicate: true,
            autoHideDuration: 1600,
          }
        );
        window.location.reload();
      } catch (error) {
        console.error("Error al unsubscribe User:", error);
        if (error.response && error.response.data) {
          console.error("Detalles del error:", error.response.data);
          setSpinner(false);
          enqueueSnackbar(error.response.data.error, {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        } else {
          console.error("Error desconocido:", error.toString());
          setSpinner(false);
          enqueueSnackbar("Error desconocido al unsubscribe User", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        }
      }
    }
  };

  const handleChangeForm = (event) => {
    setForm({ ...form, motivo: event.target.value });
  };
  const handleBlock = (item) => {
    setSelectedItem(item);
    setOpen(true);
    setBlock(true);
  };

  const getDate = (date) => {
    const date1 = new Date(date);
    const year = date1.getFullYear();
    const mounth = date1.getMonth() + 1;
    const day = date1.getDate();

    return `${day < 10 ? "0" + day : day}/${
      mounth < 10 ? "0" + mounth : mounth
    }/${year}`;
  };

  const getHistorial = async (item) => {
    const ref = doc(db, item.path, "/HistorialMovimientos/Sesiones");
    const response = await getDoc(ref);
    const historial = response?.data()?.Movimientos || [];

    // Ordenar el historial por la fecha de inicio en orden descendente
    historial.sort((a, b) => b.horaInicio - a.horaInicio);

    return { Movimientos: historial, id: response.id, ref: response.ref };
  };

  const [responsibleData, setResponsibleData] = useState(null);
  // Función para obtener los datos del responsable del usuario
  const getResponsibleData = async () => {
    const segments = selectedItem?.unsubscribe?.responsible?._path?.segments;

    if (segments && segments.length >= 4) {
      const fourSegment = segments[3];
      console.log(fourSegment);
      if (selectedItem?.unsubscribe?.responsible) {
        const response = await servidor.post(
          "/movilabs/getUseReferenceData",
          {
            store_id: store,
            userId: fourSegment,
          },
          {
            headers: {
              uid: user.uid,
            },
          }
        );
        setResponsibleData(response);
      }
    } else {
      console.error(
        "No se pueden obtener los datos del responsable: segments no está definido o no tiene al menos cuatro elementos"
      );
    }
  };

  // Llama a la función cuando el componente se monta o cuando cambia el usuario seleccionado
  useEffect(() => {
    getResponsibleData();
  }, [selectedItem]);
  if (loading && !users.length)
    return (
      <>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          <CircularProgress />
        </Stack>
      </>
    );

  return (
    <>
      <List sx={design.root} subheader={<li />}>
        {Object.entries(groupedData).map(([branch, branchData], index) => (
          <li key={`section-${index}`}>
            <ul>
              <ListSubheader style={design.title}>{`Sucursal: ${
                castBranche()[branch]
              }`}</ListSubheader>
              {branchData.map((item, itemIndex) => (
                <>
                  <ListItem
                    style={{
                      backgroundColor:
                        item.unsubscribe?.reason === "Verificar información"
                          ? "#D9EDFF"
                          : "#F37F8D",
                    }}
                    key={`item-${index}-${itemIndex}`}
                    onClick={() => handleClickOpen(item)} // details user
                    secondaryAction={
                      item.unsubscribe?.reason === "Verificar información" ? (
                        <Tooltip
                          arrow
                          title={`Bloquear a ${item.name} ${item.last_name}`}
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleBlock(item)}
                          >
                            <PersonOffIcon style={design.icon} />
                          </IconButton>
                        </Tooltip>
                      ) : null
                    }
                  >
                    <ListItemAvatar>
                      <Tooltip arrow title={item.name}>
                        <Avatar
                          sx={{ width: 56, height: 56, marginRight: 2 }}
                          alt={item.name}
                          src={item.profileURL}
                        />
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                      style={{
                        textDecoration:
                          item.unsubscribe?.reason === "Verificar información"
                            ? "normal"
                            : "line-through",
                      }}
                      primary={`${item.name} ${item.last_name}`}
                      secondary={`${item.email}  ${item.phone}`}
                    />
                  </ListItem>
                </>
              ))}
            </ul>
          </li>
        ))}
      </List>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        {selectedItem && (
          <DialogContent>
            {console.log("fechaAlta", selectedItem?.highdate)}
            <DialogTitle>
              <Stack direction="row">
                <Avatar
                  sx={{ width: 90, height: 90, marginRight: 2 }}
                  alt={selectedItem.name}
                  src={selectedItem.profileURL}
                />
                <p style={design.subtitle}>
                  {`${selectedItem.name} ${selectedItem.last_name}`}
                  <br />
                  {castBranche[selectedItem.branch]}{" "}
                  {selectedItem.rol && -selectedItem.rol}
                </p>
              </Stack>
              <small>
                {selectedItem.email} - {selectedItem.phone}
                <br />
                Fecha de nacimiento: {" " + getDate(selectedItem?.birthday)}
              </small>
              {!selectedItem?.highdate ? null : (
                <p>
                  Datos de alta: realizado por{" "}
                  {responsibleData?.data?.DatosPersonales?.nombre +
                    " " +
                    responsibleData?.data?.DatosPersonales?.apellidos +
                    " "}
                  el{" "}
                  {" " +
                    timeStamp_newDate_toStringComplete(selectedItem?.highdate)}
                  .
                </p>
              )}
              {selectedItem.unsubscribe?.reason ===
              "Verificar información" ? null : (
                <p>
                  Datos de baja: realizado por{" "}
                  {responsibleData?.data?.DatosPersonales?.nombre +
                    " " +
                    responsibleData?.data?.DatosPersonales?.apellidos +
                    " "}
                  el{" "}
                  {timeStamp_newDate_toStringComplete(
                    selectedItem?.unsubscribe?.date
                  )}{" "}
                  con motivo de {selectedItem?.unsubscribe?.reason}.
                </p>
              )}
            </DialogTitle>
            {block && (
              <>
                <div>
                  <CustomTextField
                    label="Motivo de bloqueo"
                    type="text"
                    value={form.motivo}
                    grid={12}
                    required={true}
                    multiline={true}
                    name="motivo-de-bloqueo-personal"
                    onChange={handleChangeForm}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {spinner ? (
                    <>
                      <div style={design.rootSpin}>
                        <CircularProgress />
                      </div>
                    </>
                  ) : (
                    <DarkGreenButton width="99%" onClick={unsubscribeUser}>
                      Continuar
                    </DarkGreenButton>
                  )}
                </div>
              </>
            )}
            {!block && (
              <StripedGrid
                loading={false}
                columns={columns}
                rows={historialMov?.map((row, idx) => ({
                  ...row,
                  id: row.np,
                }))}
                oddBackgroundColor="#E6E6E640"
                evenBackgroundColor="#CCCCCC66"
                oddOpacity={0.7}
                w="93%"
                pageSize={10}
              />
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default ListUsers;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    fontSize: 17,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  root: {
    width: "90%",
    border: "2px solid #ABD7FF",
    borderRadius: 5,
    bgcolor: "#D9EDFF",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    "& ul": { padding: 0 },
  },
  icon: {
    color: "#6784F6",
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
  },
  rootSpin: {
    marginLeft: "50%",
  },
};

const columns = [
  {
    field: "horaInicio",
    headerName: "Hora inicio",
    width: 240,
    renderHeader: () => <p style={design.headerTable}>Hora inicio</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      return row?.horaInicio
        ? `${timeStamp_toDateComplete(row.horaInicio)}`
        : "Sin registro";
    },
  },
  {
    field: "horaCierre",
    headerName: "Hora cierre",
    width: 240,
    renderHeader: () => <p style={design.headerTable}>Hora cierre</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      return row?.horaCierre
        ? `${timeStamp_toDateComplete(row.horaCierre)}`
        : "Sin registro";
    },
  },
  {
    field: "browser",
    headerName: "Navegador",
    width: 220,
    renderHeader: () => <p style={design.headerTable}>Navegador</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => row?.browser || "Sin registro",
  },
  {
    field: "dispositivo",
    headerName: "Dispositivo",
    width: 160,
    renderHeader: () => <p style={design.headerTable}>Dispositivo</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => row?.dispositivo || "Sin registro",
  },
  {
    field: "ip",
    headerName: "IP",
    width: 140,
    renderHeader: () => <p style={design.headerTable}>IP</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => row?.ip || "Sin registro",
  },
  {
    field: "versionAplicacion",
    headerName: "Versión Aplicación",
    width: 120,
    renderHeader: () => <p style={design.headerTable}>Versión</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) =>
      row?.versionAplicacion ? `${row.versionAplicacion} v.` : "Sin registro",
  },
];
